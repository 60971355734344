import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SidenavComponent } from 'src/app/layout/sidenav/component/sidenav.component';
import { SvgIconModule } from 'src/app/kernel/tools/svg-icon/svg-icon.module';
import { RouterModule } from '@angular/router';
import { TranslationModule } from 'src/app/kernel/translations/modules/translation.module';



@NgModule({
  declarations: [
    SidenavComponent
  ],
  imports: [
    CommonModule,
    SvgIconModule,
    RouterModule,
    TranslationModule.forRoot('../../../../../assets/i18n/root/')
  ],
  exports:[SidenavComponent]
})
export class SidenavModule { }

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DashboardLayoutComponent } from 'src/app/layout/dashboard-layout/component/dashboard-layout.component';
import { SidenavModule } from 'src/app/layout/sidenav/sidenav.module';
import { RouterModule } from '@angular/router';
import { TopNavModule } from 'src/app/layout/top-nav/top-nav.module';
import { PopupWrapperModule } from 'src/app/kernel/popups/popup-wrapper/popup-wrapper.module';



@NgModule({
  declarations: [
    DashboardLayoutComponent
  ],
  imports: [
    CommonModule,
    SidenavModule,
    RouterModule,
    TopNavModule,
    PopupWrapperModule
  ],
  exports:[DashboardLayoutComponent]
})
export class DashboardLayoutModule { }
